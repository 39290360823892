/*global angular: false */

let am = angular.module('mtz-directives');

/**
  * Example usage:
  * <span mtz-date-picker name="birthday"
  *   ng-model="person.birthday" required="true"></span>
  *
  * If ng-model refers to an object property that may be null,
  * also specify model-obj and model-prop like this:
  * <span mtz-date-picker name="birthday"
  *   model-obj="person" model-prop="bdProp"
  *   ng-model="person.birthday" required="true"></span>
  * where bdProp is a scope property whose value is the string "birthday".
  *
  * The name attribute is important for AngularJS form validation.
  */
am.directive('mtzDatePicker', [() => {
  return {
    replace: true,
    restrict: 'AE',
    templateUrl: 'feature/share/directives/datepicker.html',
    scope: {
      fixPosition: '=',
      name: '@',
      maxDate: '=',
      minDate: '=',
      ngChange: '&',
      ngModel: '=',
      placeholder: '@',
      readonly: '=',
      required: '='
    },
    link(scope) {
      // Fix the model.
      let model = scope.ngModel;
      if (model) {
        model.setHours(0);
        model.setMinutes(0);
        model.setSeconds(0);
        model.setMilliseconds(0);
      }

      scope.changed = () => scope.ngChange();

      scope.popupOpen = false;

      scope.openDatePicker = event => {
        // Hide all the date pickers, in case any were already visible.
        $('.date-picker > ul').hide();

        let datePicker = $(event.target).closest('.date-picker');
        let ul = datePicker.children('ul');

        if (scope.fixPosition) {
          let input = datePicker.children('input');
          let height = input.outerHeight();
          let offset = input.offset();

          setTimeout(() => {
            let windowJq = $(window);
            let scrollLeft = windowJq.scrollLeft();
            let scrollTop = windowJq.scrollTop();

            let props = {
              position: 'fixed',
              left: offset.left - scrollLeft,
              top: offset.top + height - scrollTop
            };
            ul.css(props);
            ul.show();
          }, 100);
        } else {
          ul.show();
        }

        // Prevent the click event that trigger this from bubbling.
        event.preventDefault();
        event.stopPropagation();

        scope.popupOpen = true;
      };
    }
  };
}]);
